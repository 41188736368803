/**
 * Displays Message content of a message in a selected channel
 */
 import React from "react";
 import { useStyles } from "../../style/messages";
 import { Box, Grid, Typography, IconButton, Avatar } from "@material-ui/core";
 import { LightTooltip } from "../../style/tooltip";
 import { getModeratedMessageTooltip, getModeratedReasonTooltip } from "../../utils/helpers";
 
 const MessageContent = (props) => {
   const { message, confirmDelete, confirmForward, updateMessage, confirmUndo, index, toggledVal, confirmPin } = props;
   const classes = useStyles();

  //  console.log(message);
   return (
     <>
       {toggledVal === "chat" && (
         <Grid container className={classes.messageContainer}>
           {message.actions && (message.actions === "deleted" || message.actions.deleted) ? (
             <>
               <span
                 className={classes.chat}
                 style={{
                   backgroundColor: index % 2 !== 0 ? "#FFFFFF" : "#EEF3FF",
                 }}
               >
                 <Typography className={classes.deletedChat}>{message.message.text}</Typography>
               </span>
               <Box ml={2}>
                 <LightTooltip title="Undo">
                   <IconButton
                     id="undoIcon"
                     edge="start"
                     onClick={(event) => confirmUndo(event, message)}
                   >
                     <img src={process.env.PUBLIC_URL + "/images/undo.svg"} alt="delete" />
                   </IconButton>
                 </LightTooltip>
               </Box>
             </>
           ) : (
             <>
               <span
                 className={classes.chat}
                 style={{
                   backgroundColor: index % 2 !== 0 ? "#FFFFFF" : "#EEF3FF",
                 }}
               >
                 {message.message.text && (
                   <Typography className={classes.chatText}>{message.message.text}</Typography>
                 )}
                 {message.file && message.file.url && (
                   <>
                     <Avatar src={message.file.url} className={classes.image} />
                   </>
                 )}
               </span>
 
               <Box ml={2}>
                 <LightTooltip title="Edit">
                   <IconButton
                     id="updateIcon"
                     edge="start"
                     label="edit"
                     onClick={(event) => updateMessage(event, message)}
                   >
                     <img src={process.env.PUBLIC_URL + "/images/pencil.svg"} width="20" height="20" alt="edit" />
                   </IconButton>
                 </LightTooltip>
                 <LightTooltip title="Delete">
                   <IconButton
                     id="deleteIcon"
                     edge="start"
                     label="delete"
                     onClick={(event) => confirmDelete(event, message)}
                   >
                     <img src={process.env.PUBLIC_URL + "/images/trash-can.svg"} width="20" height="20" alt="delete" />
                   </IconButton>
                 </LightTooltip>
                 <LightTooltip title="Pin">
                   <IconButton
                     id="pinIcon"
                     edge="start"
                     label="pin"
                     onClick={(event) => confirmPin(event, message)}
                   >
                     <img src={process.env.PUBLIC_URL + "/images/thumbtack.svg"}  width="20" height="20" alt="flag" />
                   </IconButton>
                 </LightTooltip>

                 <LightTooltip title="Forward this message to dashboard">
                   <IconButton
                     id="forwardMessage"
                     edge="start"
                     label="forwardMessager"
                     onClick={(event) => confirmForward(event, message)}
                   >
                     <img src={process.env.PUBLIC_URL + "/images/message-question.svg"} width="20" height="20" alt="forward message" />
                   </IconButton>
                 </LightTooltip>
               </Box>
             </>
           )}
         </Grid>
       )}
       {toggledVal === "banned" && (
         <Grid container>
           <>
             <span
               className={classes.chat}
               style={{
                 backgroundColor: index % 2 !== 0 ? "#FFFFFF" : "#EEF3FF",
               }}
             >
               <Typography className={classes.chatText}>
                 <LightTooltip
                   title={
                     <>
                       <Typography>{getModeratedReasonTooltip(message.reason)}</Typography>
                       <br />
                       <Typography>
                         {getModeratedMessageTooltip(message.moderatedMessage)}
                       </Typography>
                     </>
                   }
                 >
                   <Typography className={classes.chatText}>{message.originalMessage}</Typography>
                 </LightTooltip>
               </Typography>
               {message.image && (
                 <>
                   <IconButton className={classes.imageContainer}>
                     <img className={classes.image} src={message.image} alt="sentImage" />
                     {message.imageReason && (
                       <LightTooltip
                         title={
                           <>
                             <Typography>{`Reason: ${
                               message.imageReason[0] && message.imageReason[0].text
                             }`}</Typography>
                           </>
                         }
                         placement="right-start"
                       >
                         <img
                           src={process.env.PUBLIC_URL + "/images/info.svg"}
                           alt="profilePicture"
                           className={classes.imageIcon}
                         />
                       </LightTooltip>
                     )}
                   </IconButton>
                 </>
               )}
             </span>
           </>
         </Grid>
       )}
     </>
   );
 };
 
 export default MessageContent;