import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    opacity: 1,
    background: "#F7F8FA 0% 0% no-repeat padding-box",
    height: "50px",
  },
  messages: {
    padding: theme.spacing(2),
    opacity: 1,
    border: "1px solid #F3F3F3",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000014",
    height: "57vh",
    overflow: "auto",
  },
  chat: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "0px 10px 10px 10px",
    opacity: 1,
    height: "auto",
    width: "auto",
    border: "1px solid #DDDDDD",
    marginBottom: "5px",
    marginLeft: "8%",
    maxWidth: "40%",
  },
  values: {
    color: "#414141",
    textAlign: "left",
    opacity: 1,
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-23) var(--unnamed-font-family-proxima-nova)",
  },
  avatar: {
    background: "#EACAFF",
    color: "#BD59FF",
    borderRadius: "8px",
  },
  appName: {
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    fontSize: "12px",
    color: "#C2C2C2",
    opacity: 1,
  },
  timeField: {
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 11px/13px var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#C2C2C2",
    opacity: 1,
    marginBottom: "10px",
    marginLeft: "8%",
  },
  channelMember: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    opacity: 1,
    height: "48px",
    width: "100%",
    marginBottom: "20px",
  },
  user: {
    font: "var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-14)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#4B5155",
    opacity: 1,
    marginBottom: "10px",
    marginLeft: "3%",
  },
  chatText: {
    font: "var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#414141",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  members: {
    font: "var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-14)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#4B5155",
    opacity: 1,
    margin: "10px 0px 10px 10px",
  },
  channelMembers: {
    padding: theme.spacing(2),
    opacity: 1,
    border: "1px solid #F3F3F3",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000014",
    maxHeight: "74vh",
    overflow: "auto",
  },
  totalCount: {
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-15) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#414141",
    opacity: 1,
    marginBottom: "10px",
  },
  userProfile: {
    borderRadius: "8px",
  },
  totalUsers: {
    background: "#C2C2C2 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    font: "var(--unnamed-font-style-normal) normal 600 11px/13px var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#FFFFFF",
    height: "auto",
    width: "25px",
  },
  onlineUsers: {
    background: "#76C00D 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    font: "var(--unnamed-font-style-normal) normal 600 11px/13px var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#FFFFFF",
    height: "auto",
    width: "25px",
  },
  alertMessage: {
    background: "transparent",
  },
  messagesCount: {
    background: "#E5EAF9 0% 0% no-repeat padding-box",
    borderRadius: "0px 0px 5px 5px",
    opacity: 1,
    textTransform: "none",
  },
  messagesCountFont: {
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    color: "#6387ED",
    opacity: 1,
    textTransform: "camelcase",
  },
  deletedChat: {
    font: "var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "left",
    color: "#414141",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "20px",
    marginRight: "20px",
    textDecoration: "line-through",
  },
  messageContainer: {
    "& button": {
      display: "none",
    },
    "&:hover": {
      "& button": {
        display: "inline-block",
      },
    },
  },
  activeToggled: {
    background: "#6387ED 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px Graphik",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#6387ED",
    },
  },
  disableToggled: {
    background: "#E5EAF9 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "var(--unnamed-character-spacing-0)",
    textAlign: "center",
    font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px Graphik",
    color: "#6387ED",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E5EAF9",
    },
  },
  messageBox: {
    background: "#F7F8FA 0% 0% no-repeat padding-box",
    border: "1px solid #DBE5ED",
    borderRadius: "8px 8px 0px 0px",
    opacity: 1,
    height: "auto",
    borderRight: "#6387ED",
  },
  editMessageHeader: {
    font: "var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    color: "#414141",
    opacity: 1,
    marginTop: "10px",
    marginLeft: "20px",
  },
  editMessageFont: {
    font: "var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-proxima-nova)",
    letterSpacing: "var(--unnamed-character-spacing-0)",
    color: "#414141",
    opacity: 1,
    marginTop: "10px",
    marginLeft: "20px",
    marginBottom: "10px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  image: {
    maxWidth: "240px",
    maxHeight: "165px",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: "1px solid #DDDDDD",
    borderRadius: "0px 10px 10px 10px",
  },
  imageIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  imageContainer: {
    padding: "0px",
    cursor: "default",
  },
}));
