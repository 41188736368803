import React from "react";
import AccountList from "../../components/accounts/AccountList";

const Account = () => {
  return (
    <>
      <AccountList />
    </>
  );
};
export default Account;
