/**
 * Displays Messages in a selected channel
 */
import React, { useState, useEffect, useRef } from "react";
import { Avatar, Box, Grid, Typography, CircularProgress, Button } from "@material-ui/core";
import { useStyles } from "../../style/messages";
import Alert from "@material-ui/lab/Alert";
import { formatUserName, formatProfileImageUrl } from "../../utils/helpers";
import ConfirmDialog from "../core/ConfirmDialog";
import { softDeleteMessage, addPinMessageAction } from "../../services/pubnub";
import UndoDelete from "./UndoDelete";
import EditMessage from "./EditMessage";
import MessageContent from "./MessageContent";

export default function Chat(props) {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenPin, setConfirmOpenPin] = useState(false);
  const [confirmOpenForward, setConfirmOpenForward] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [undoConfirmOpen, setUndoConfirmOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [listenerAdded, setListenerAdded] = useState(false);
  const messagesEndRef = useRef(null);

  
  // used for throttle scroll
  const _ = require('lodash');
  const listRef = useRef(null);

  /**
   * Handles delete icon click to confirm delete message action
   */
  const confirmDelete = (event, message) => {
    setConfirmOpen(true);
    setSelectedMessage(message);
  };

  const confirmPin = (event, message) => {
    setConfirmOpenPin(true);
    setSelectedMessage(message);
  }

  const confirmForward = (event, message) => {
    console.log('---> confirmforward', event, message)
    setConfirmOpenForward(true);
    setSelectedMessage(message);
  }





  /**
   * Handles soft deleting a message in a channel
   */
  const deleteMessage = () => {
    (async () => {
      try {
        const response = await softDeleteMessage(
          props.pubnub,
          props.channel,
          selectedMessage.timetoken
        );
        props.updated(selectedMessage.timetoken, response.actionTimetoken);
      } catch (e) { }
    })();
  };


  const pinMessage = () => {
    console.log('pin message', selectedMessage);
    (async () => {
      try {
        const response = await addPinMessageAction(
          props.pubnub,
          props.channel,
          selectedMessage.timetoken
        );
      } catch (e) { console.log(e) }

    })()
  }

  const forwardMessage = () => {
    console.log('forward message', selectedMessage)

    const {text} = selectedMessage.message
    const {id} = selectedMessage.message.meta
    
    const {channel} = props
    const channelInfo = channel.split('-')


    const eventSession = channelInfo.length == 2 ? `&eventID=${channelInfo[1]}` : `&eventID=2883&sessionID=${channelInfo[0]}`


    const url = `https://anesthesiologie.nl/vragen?userID=${id}${eventSession}&question=${encodeURIComponent(text)}`

    window.open(url, '_blank').focus()


  }

  /**
   * Handles undo icon click to confirm recovering a message
   */
  const confirmUndo = (event, message) => {
    setUndoConfirmOpen(true);
    setSelectedMessage(message);
  };

  /**
   * Handles edit icon click to edit a message
   */
  const updateMessage = (event, message) => {
    //  console.log('chat.js::updateMessage',message);
    props.setMessageToEdit(message);
  };



  useEffect(() => {
    console.log('# of messages: ',messages.length)

  },[messages])

  useEffect(() => {
    setMessages(props.messages);
    const diffBottom = Math.abs(listRef.current?.offsetHeight + listRef.current?.scrollTop - listRef.current?.scrollHeight);
    if (hasScrolled && props.endLoad && diffBottom < 100) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView();
      }, 100);
    }

    setHasScrolled(true);
  }, [props.messages]);


  const scrollExec = (e) => {
      props.setScrollPosition(e.target.scrollTop === 0 ? 0 : 1);
  }



  const exportMessages = () => {
    console.log(messages.length)
    
    // const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(messages));
    downloadObjectAsJson(messages, `messages-export-${props.channel}`);
  }

  const downloadObjectAsJson = (exportObj, exportName) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  useEffect(() => {
    setTimeout(() => {
      if (listRef && listRef.current ) {
        if (listenerAdded) return;
        setListenerAdded(true);
        listRef.current.addEventListener('scroll', _.throttle(scrollExec, 200));

        return () => {
          listRef.current?.removeEventListener('scroll',_.throttle(scrollExec, 200));
        }
      }
    },2500);

  },[listenerAdded])


  return (
    <Grid>
      {messages.length || props.isLoading ? (
        <Grid item className={classes.messages} ref={listRef} >
          {messages.map((message, n) => {
            return (
              <div key={n}>
                {props.toggledVal === "chat" && (
                  <>
                    <Grid container>
                      <Box>
                        <Avatar variant="square" src={formatProfileImageUrl(message.profileUrl)} />
                      </Box>
                      <Typography className={classes.user}>
                        {formatUserName(message.name)}
                      </Typography>
                    </Grid>
                    <MessageContent
                      id="messageContent"
                      message={message}
                      confirmDelete={confirmDelete}
                      updateMessage={updateMessage}
                      confirmUndo={confirmUndo}
                      confirmPin={confirmPin}
                      confirmForward={confirmForward}
                      index={n}
                      toggledVal={props.toggledVal}
                    />
                    <Typography className={classes.timeField}>{message.time}</Typography>
                  </>
                )}
                {props.toggledVal === "banned" && (
                  <>
                    <Grid container>
                      <Box>
                        <Avatar variant="square" src={formatProfileImageUrl(message.profileUrl)} />
                      </Box>
                      <Typography className={classes.user}>
                        {formatUserName(message.name)}
                      </Typography>
                    </Grid>
                    <MessageContent message={message} toggledVal={props.toggledVal} index={n} />
                    <Typography className={classes.timeField}>{message.time}</Typography>
                  </>
                )}
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </Grid>
      ) : (
        <Alert severity={"info"} className={classes.alertMessage}>
          No recent messages found
        </Alert>
      )}

      <ConfirmDialog
        id="delete"
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteMessage}
        actionMessage={"Yes, Delete it"}
      >
        You can always undo this action
      </ConfirmDialog>

      <ConfirmDialog
        id="pin"
        title="Pin this message?"
        open={confirmOpenPin}
        setOpen={setConfirmOpenPin}
        onConfirm={pinMessage}
        actionMessage={"Yes, pin it"}
      >
        You can not undo this action
      </ConfirmDialog>


      <ConfirmDialog
        id="forward"
        title="Forward this message to dashboard?"
        open={confirmOpenForward}
        setOpen={setConfirmOpenForward}
        onConfirm={forwardMessage}
        actionMessage={"Yes, forward message"}
      >
        You can not undo this action
      </ConfirmDialog>

      <UndoDelete
        title="Are you sure?"
        open={undoConfirmOpen}
        setOpen={setUndoConfirmOpen}
        channel={props.channel}
        pubnub={props.pubnub}
        message={selectedMessage}
        updated={props.updated}
      >
        You want to recover the message
      </UndoDelete>
      {props.isLoading ? (
        <Grid justify="center" container>
          <Box pt={3}>
            <CircularProgress />
          </Box>
        </Grid>
      ) : null}
      <EditMessage
        message={props.messageToEdit}
        channel={props.channel}
        pubnub={props.pubnub}
        updated={props.updated}
        messagesLength={messages.length}
      />
      <Button onClick={exportMessages}>
        Export this chat
      </Button>
    </Grid>
  );
}